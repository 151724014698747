.call-to-action-container {
    @apply tw-mt-10 tw-grid tw-items-center;
    grid-template-rows: auto;
    grid-template-areas:
        'call'
        'email'
        'emailForm'
        'chat'
    ;
}

.call-to-action {
    @apply tw-py-6 tw-px-1 tw-text-xl tw-font-light tw-border-t tw-border-gray-400;
    //border-top: 1px solid theme('colors.gray.400');
    :global .ButtonAnchor {
        @apply tw-text-xl tw-font-light;
    }
}

.action-icon {
    @apply tw-mr-4;
    height: 32px;
}

// specific modications for each call-to-action
.call-to-action{
    &.call {
        grid-area: call;
        a {
            @apply tw-flex tw-items-end;
        }
        a:hover {
            @apply tw-text-gray;
        }
        .action-label {
            @apply tw-text-base;
        }
        .action-icon {
            @apply tw-mr-1;
        }
    }
    &.email {
        grid-area: email;
    }
    &.emailForm {
        @apply tw-text-base;
        grid-area: emailForm;
    }
    &.chat {
        grid-area: chat;
        p{
            @apply tw-m-0;
        }
    }
}

.live-chat-offline-text {
    @apply tw-text-3xs tw-pl-12;
    &::before {
        @apply tw-text-lg tw-p-0 tw-pt-1 tw-text-red tw-relative;
        content: '•';
        top: 2px;
    }
}

.modal-site-messaging {
	:global .site-message {
		@apply tw-bg-white tw-text-red tw-pl-0 #{!important};
        .site-secondary-message {
			@apply tw-flex tw-flex-col tw-items-start #{!important};
			p {
				@apply tw-mb-1 tw-text-left #{!important};
                &:first-of-type {
					@apply tw-text-lg #{!important};
				}
			}
		}
	}
    :global .site-message.site-message-clearance {
        @apply tw-hidden;
    }
};

@screen lg {
    .call-to-action-container {
        @apply tw-mb-6 tw-grid tw-grid-cols-3 tw-divide-x tw-divide-gray-400 tw-items-center;
        grid-template-rows: auto;
        grid-template-areas:
            'call email chat'
            'emailForm emailForm emailForm'
        ;
    }
    .call-to-action {
        @apply tw-pt-8 tw-pb-6 tw-border-t-0;
        &:first-of-type {
            @apply tw-pl-0;
        }
    }
    .call-to-action.call {
        @apply tw-pt-2;
        grid-area: call;
    }
    .call-to-action.email {
        @apply tw-pt-2 tw-text-center;
        grid-area: email;
    }
    .call-to-action.emailForm {
        @apply tw-pt-2 tw-px-0 tw-text-sm;
        grid-area: emailForm;
    }
    .call-to-action.chat {
        @apply tw-pt-2 tw-px-3 tw-border-r-0;
        grid-area: chat;
    }
    .here-to-help-modal {
        :global .MagicModal {
            overflow: hidden;
        }
    }
}