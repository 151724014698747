.footer-social-link {
	@apply tw-relative;

	&:focus, &:hover {
		&:before {
			@apply tw-absolute tw-bg-white tw-h-full tw-left-0 tw-rounded-full tw-top-0 tw-w-full;
			content: '';
		}

		.footer-social-link-image {
			@apply tw-visible;
		}

		.footer-social-link-image-overlay {
			@apply tw-block;
			filter: invert(0.9) sepia(1) saturate(60) hue-rotate(190deg);
		}
	}
}

.footer-social-link-image {
	@apply tw-invisible tw-outline-none;
}

.footer-social-link-image-overlay {
	@apply tw-absolute tw-left-0 tw-top-0;
	margin: 1.5px;
}
