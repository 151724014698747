.CraftpersonsList {
	li {
		@apply tw-mb-2;
		a {
			@apply tw-text-gray tw-no-underline;
			&:hover {
				@apply tw-text-blue tw-underline;
			}
			&.is-active {
				@apply tw-underline;
			}
		}
	}
	&.CraftpersonsListAccordion {
		columns: 2;
		-webkit-columns: 2;
		-moz-columns: 2;
		@apply tw-mt-3;
		li {
			@apply tw-mb-4;
		}
	}
}