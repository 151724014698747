@import 'css/lib/tailwind-custom/triangle';

.lineItemPricing {
	@apply tw-border-gray-400 tw-border-t tw-border-b tw-w-full tw-py-1;
	th {
		@apply tw-text-xs md:tw-text-2xs tw-font-normal tw-pt-3 tw-pb-1 tw-align-top;
	}
	td {
		@apply tw-pt-1 tw-pb-3 tw-align-top;
	}
}
.verticalDivider {
	@apply tw-hidden md:tw-block tw-col-span-1;
	&::before {
		@apply tw-bg-gray-400 tw-w-px tw-block tw-left-1/2 tw-relative;
		content: '';
		top: 1rem;
		height: calc(100% - 2rem);
	}
}
.summaryColumnEditOrder {
	flex-basis: 100%;
	max-width: 385px;
}
.summaryBoxEditOrder {
	@apply tw-border tw-border-gray-400 tw-px-1 tw-py-4 tw-space-y-6 md:tw-px-4;
}
:global(.view-state-container-MAIN) .deliveryNotificationsForm {
	@apply tw-col-span-full tw-space-y-8;
}
:global(.view-state-container-ADD_NEW) .deliveryNotificationsForm {
	@apply tw-col-span-full tw-space-y-4;
}
.summaryGrid {
	@apply tw-grid tw-w-full tw-gap-4 print:tw-border-gray-400 print:tw-border-b print:tw-pb-4;
	grid-template-columns: minmax(0, 1fr) min-content;
}
.lineItemStatusGray {
	--triangleColor: theme('colors.gray.DEFAULT');
}
.lineItemStatusRed {
	--triangleColor: theme('colors.red.DEFAULT');
}
.lineItemStatusGray,
.lineItemStatusRed {
	--triangleSize: 10px;
	@apply tw-relative;
	padding-top: var(--triangleSize);
	&::before {
		@apply tw-absolute tw-top-0;
		left: calc(50% - var(--triangleSize));
		@include triangle(var(--triangleSize), var(--triangleColor), up);
		content: '';
	}
}
.giftCardGunScannerView {
	@apply tw-flex tw-absolute tw-justify-center tw-top-0 tw-bottom-0;
	width: calc(100% - 3rem);
}