.verticalDelimiter {
	@apply tw-relative tw-pr-3;
	&::after {
		@apply tw-block tw-bg-gray-300 tw-absolute tw-right-0;
		content: '';
		width: 1px;
		height: calc(100% - 10px);
		top: 50%;
		transform: translateY(-50%);
	}
}
.verticalDelimiterGroup {
	@apply tw-flex tw-gap-3;
	> *:not(:last-child) {
		@extend .verticalDelimiter;
	}
}
.mdVerticalDelimiter {
	@apply tw-relative md:tw-pr-3;
	&::after {
		@apply tw-hidden md:tw-block tw-bg-gray-300 tw-absolute tw-right-0;
		content: '';
		width: 1px;
		height: calc(100% - 10px);
		top: 50%;
		transform: translateY(-50%);
	}
}
.mdVerticalDelimiterGroup {
	@apply tw-flex tw-gap-3 tw-flex-col md:tw-flex-row;
	> *:not(:last-child) {
		@extend .mdVerticalDelimiter;
	}
}