.feature-toggle-modal button {
	@apply tw-cursor-pointer;
}
.toggle-mgmt-container {
	@apply tw-flex tw-items-center;
}
.toggle-mgmt-controls {
	@apply tw-flex tw-items-baseline tw-m-0;
}
.toggle-mgmt-control {
	margin: 0 20px 0 0;
}
.toggle-message-text {
	@apply tw-flex tw-items-center;
	font-size: 15px;
	> * {
		margin: 0 0 0 10px;
	}
}
.toggle-mgmt-icon {
	@apply tw-flex tw-opacity-50;
	img {
		width: 18px;
	}
}
.location-badge {
	@apply tw-text-center tw-text-3xs tw-font-bold tw-inline-block tw-text-white tw-bg-gray-200;
	border-radius: 50%;
	line-height: 21px;
	height: 21px;
	width: 26px;
	&:focus {
		@apply tw-text-white #{'!important'};
	}
}
.feature-toggles {
	@apply tw-flex tw-flex-col tw-w-auto;

	.left-column {
		flex: 60;
		word-break: break-word;
		margin: 0 10px 0 0;
	}
	.jira-column {
		flex: 10;
	}
	.middle-column {
		flex: 10;
		text-align: center;
	}
	.right-column {
		flex: 7;
		justify-content: flex-end;
		text-align: center;
	}
	.locations-column {
		@apply tw-text-center tw-relative;
		flex: 5;
		right: -30px;
	}
	.feature-toggles-header {
		@apply tw-flex tw-border-b tw-border-solid tw-border-gray-200;
		padding: 0 5px;
		margin: 5px 0;
		h3 {
			@apply tw-text-brown tw-uppercase;
			font-size: 13px;
			letter-spacing: 1px;
			margin-bottom: 6px;
		}
	}

	.feature-toggle {
		@apply tw-flex tw-items-baseline tw-text-gray;
		padding: 5px;
		// Duplicate class for specificity.
		&.feature-toggle:hover {
			@apply tw-bg-gold;
		}
	}
	.feature-toggle-row:nth-child(odd) {
		@apply tw-bg-gray-500;
	}
	.feature-toggle-section {
		overflow: auto;
		max-height: 650px;
	}
	.feature-toggle-section + .feature-toggle-section {
		margin: 20px 0 0;
	}
	.feature-toggle-section.feature-toggle-section-internal {
		@apply tw-bg-gray-500;
		.feature-toggle-section-header {
			@apply tw-border-b tw-border-dotted tw-border-gray-200;
		}
	}
	.feature-toggle-section-header {
		padding: 0 5px;
		display: none;
	}
}
@screen sm {
	.feature-toggles {
		.feature-toggles-header {
			display: none;
		}
		.feature-toggle {
			@apply tw-flex-wrap;
			&:not(:last-child) {
				@apply tw-border-b tw-border-solid tw-border-gray-400;
			}
		}
		.left-column {
			flex-basis: 100%;
			word-break: break-word;
		}
		.middle-column {
			@apply tw-text-gray-300;
			margin: 10px 0;
			&::before {
				content: 'Default: ';
			}
		}
		.right-column {
			@apply tw-text-gray-300;
			margin: 10px 0;
			&::before {
				content: 'Browser: ';
			}
		}
	}
	.toggle-location-detail {
		@apply tw-text-navy tw-font-bold tw-text-2xs tw-uppercase;
		padding: 5px 20px;
		letter-spacing: 1px;
	}
	.toggle-location-detail-item {
		@apply tw-no-underline tw-tracking-normal tw-flex tw-justify-between tw-items-center tw-w-1/3 tw-normal-case;
		padding: 5px 0;
	}
	.toggle-location-switch {
		@apply tw-ml-4;
	}
}
.ribbon {
	font-weight: bold;
}
.ribbon {
	// control the folded part
	--f: .5em;
	// control the ribbon shape
	--r: .8em;

	@apply tw-bg-gold;
	position: absolute;
	top: 93px;
	right: calc(-1*var(--f));
	padding: 0.25rem 1.7rem 0.25rem 1rem;
	line-height: 1.8;
	border-bottom: var(--f) solid #0005;
	border-left: var(--r) solid #0000;
	clip-path:
			polygon(var(--r) 0,100% 0,100% calc(100% - var(--f)),calc(100% - var(--f)) 100%,
					calc(100% - var(--f)) calc(100% - var(--f)),var(--r) calc(100% - var(--f)),
					0 calc(50% - var(--f)/2));
}
@screen md {
	.feature-toggles .feature-toggle-section {
		overflow: visible;
		max-height: none;
	}
}