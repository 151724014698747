.TabsToComboBox {
	@apply tw-relative;
	.TabsToComboBox-menuListContainer {
		@apply tw-hidden;
	}
	.TabsToComboBox-rootMenuItem {
		@apply tw-no-underline tw-block tw-text-left tw-font-bold tw-text-navy tw-px-2 tw-py-3 tw-w-full;
		@apply tw-bg-gradient-to-b tw-from-white tw-to-gray-500;
	}
	.TabsToComboBox-delimiter {
		@apply tw-hidden tw-border-r tw-border-solid tw-border-gray-300 tw-h-4 tw-self-center;
	}
	&.is-open {
		.TabsToComboBox-menuListContainer {
			@apply tw-grid tw-grid-flow-row tw-absolute tw-z-10 tw-bg-white tw-pt-6 tw-pr-4 tw-pb-4 tw-pl-6 tw-w-full;
			@apply tw-border-b-0 tw-justify-start;
			grid-gap: 1.25rem 0.6rem;
			box-shadow: 0 0.1875rem 0.1875rem 0 theme('colors.gray.400');
		}
		.TabsToComboBox-rootMenuItem {
			@apply tw-text-orange;
			background: theme('colors.white.DEFAULT');
			box-shadow: 0 0.0625rem 0.1875rem 0 theme('colors.gray.400');
		}
	}
}
.TabsToComboBox.TabsToComboBox--tabsOnly {
	@apply tw-hidden;
}

@screen md {
	.TabsToComboBox {
		&.is-open .TabsToComboBox-menuListContainer,
		.TabsToComboBox-menuListContainer {
			@apply tw-grid tw-grid-flow-col tw-items-baseline tw-shadow-none tw-static tw-z-10 tw-bg-white tw-pt-4;
			@apply tw-w-full tw-border-b-0 tw-justify-start;
			grid-gap: 1.25rem 0.6rem;
		}
		.TabsToComboBox-rootMenuItem {
			@apply tw-hidden;
		}
		.TabsToComboBox-delimiter {
			@apply tw-block;
		}
	}
	.TabsToComboBox.TabsToComboBox--tabsOnly {
		@apply tw-block;
	}
	.TabsToComboBox.TabsToComboBox--comboBoxOnly {
		@apply tw-hidden;
	}
	.TabsToComboBox-menuItemAnchor,
	.TabsToComboBox-menuItemAnchorWithDelimiter {
		@apply tw-text-base tw-no-underline tw-text-gray tw-font-normal;
		&:hover, &:focus {
			@apply tw-text-blue tw-underline;
		}
		&.is-active {
			@apply tw-font-bold;
		}
	}
	.TabsToComboBox-menuItemAnchorWithDelimiter {
		@apply tw-relative;
		padding-right: 0.6rem;
		&::after {
			@apply tw-block tw-bg-gray-300 tw-absolute tw-right-0;
			content: '';
			width: 1px;
			height: calc(100% - 7px);
			top: 50%;
			transform: translateY(-50%);
		}
	}
}