.WizardSteps {
	@apply tw-mb-8 tw-flex md:tw-mb-4;
	.WizardSteps-step {
		@apply tw-relative tw-text-xs md:tw-text-sm;
		+ .WizardSteps-step {
			margin-left: 0.4rem;
		}
	}
	.WizardSteps-stepItem {
		@apply tw-no-underline tw-text-gray;
		&::after {
			@apply tw-h-0 tw-absolute tw-left-0 tw-right-0 tw-mx-auto tw-my-0 tw-w-0;
			content: '';
			transition: width 200ms ease-in-out;
			border-top: 2px solid theme('colors.gray.DEFAULT');
			bottom: -5px;
		}
	}
	.WizardSteps-stepCOMPLETE:not(.WizardSteps-stepREADONLY) {
		.WizardSteps-stepItem {
			&:hover,
			&:focus {
				&::after {
					@apply tw-w-full;
				}
			}
		}

	}
	.WizardSteps-stepACTIVE {
		.WizardSteps-stepItem {
			@apply tw-font-bold;
			&::after {
				@apply tw-w-full;
			}
		}
	}
	.WizardSteps-stepINACTIVE {
		.WizardSteps-stepItem {
			@apply tw-text-gray-300;
		}
	}
}

@screen md {
	:global(.full-width-layout.with-sidebar) .WizardSteps {
		// Accommodate full width layout with absolutely positioned sidebar.
		max-width: calc(66.6666% - 1rem);
	}
	.WizardSteps .WizardSteps-step {
		+ .WizardSteps-step {
			@apply tw-ml-7;
		}
		.WizardSteps-stepItem::after {
			border-top-width: 3px;
			bottom: -8px;
		}
	}
}
