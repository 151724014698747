.selectionBox {
	@apply hover:tw-outline-1 hover:tw-outline-offset-2 hover:tw-outline-dotted hover:tw-outline-gray-300;
	&.selected {
		@apply tw-bg-gray-600;
	}
}
.inside {
	@apply tw-w-full tw-border tw-flex tw-flex-col tw-gap-4 tw-justify-center tw-items-center tw-text-center;
	@apply md:tw-text-left md:tw-flex-row;
	&.unselected {
		@apply tw-border-gray-400;
	}
	&.selected {
		@apply tw-border-gray tw-border-b-2;
	}
	&.templateCHECKBOX {
		@apply tw-p-4 tw-justify-start;
	}
	&.templateDEFAULT {
		@apply tw-p-2;
	}
	&.templateCHECKMARK {
		@apply tw-pl-2 tw-pr-6 tw-py-4 tw-justify-start tw-items-start tw-text-left;
	}
	&.templateOPEN_DELIVERY {
		@apply tw-p-4 md:tw-justify-start md:tw-items-start;
		.label {
			@apply tw-pl-2;
		}
	}
	&.templateDECISION {
		@apply tw-text-left tw-px-2 tw-py-4 tw-block;
	}
	&.templateBAREBONES {
		@apply tw-text-center;
	}
}
.iconContainer {
	@apply tw-absolute tw-pointer-events-none tw-flex-shrink-0;
	width: 20px;
	height: 20px;
}
.label {
	@apply tw-pl-7 tw-pr-0 tw-py-0 tw-font-normal;
}
.fieldGroup {
	@apply tw-relative tw-flex tw-gap-2;
}
.iconCheckmark {
	@apply tw-rounded-full tw-w-full tw-h-full tw-border;
	@apply before:tw--rotate-45 before:tw-w-[10px];
	@apply before:tw-h-[5px] before:tw-left-[5px];
	@apply before:tw-top-[6px] before:tw-border-b;
	@apply before:tw-border-l before:tw-border-solid;
	@apply before:tw-absolute;
	&.unselected {
		@apply tw-hidden;
	}
}
.iconX {
	@apply tw-rounded-full tw-w-full tw-h-full tw-border;
	&::before,
	&::after {
		@apply tw-bg-gray tw-w-px tw-absolute;
		height: 11px;
		left: 10px;
		top: 4.5px;
		content: '';

	}
	&::before {
		@apply tw--rotate-45;
	}
	&::after {
		@apply tw-rotate-45;
	}
}
.iconCircle {
	@apply tw-rounded-full tw-w-full tw-h-full tw-border;
	&.unselected {
		@apply tw-border-gray-400;
	}
}
.radioControl {
	@apply tw-sr-only;
	&:focus + .selectionBox {
		@apply tw-outline-1 tw-outline-offset-2 tw-outline-dotted tw-outline-gray-300;
	}
}
.fullBleedLabel {
	.inside {
		&.unselected {
			// Fix for shifting surrounding boxes since selected boxes have an extra pixel of bottom border.
			@apply tw-pb-px;
		}
		@apply tw-p-0;
	}
	.fieldGroup {
		@apply tw-block tw-w-full tw-h-full;
	}
	.label {
		@apply tw-p-2;
	}
	.labelInside {
		@apply tw-relative tw-flex tw-justify-center tw-pl-2;
	}
	.iconContainer {
		@apply tw-relative;
		left: -8px;
	}
	.templateDECISION {
		.label {
			@apply tw-px-2 tw-py-4;
		}
		.labelInside {
			@apply tw-justify-start;
		}
	}
	.templateOPEN_DELIVERY {
		.label {
			@apply tw-p-4;
		}
		.labelInside {
			@apply tw-justify-start tw-pl-0 tw-gap-4;
		}
	}
	.templateBAREBONES {
		.label {
			@apply tw-p-4 tw-font-normal;
		}
	}
	.templateCHECKMARK {
        .label {
			@apply tw-p-4;
		}
		.labelInside {
			@apply tw-justify-start tw-pl-0;
		}
    }
}
.templateCHECKMARK .fieldGroup {
	@apply tw-flex-wrap;
}
.readOnly {
	.selectionBox,
	.radioControl:focus + .selectionBox {
		@apply hover:tw-outline-none tw-outline-none;
	}
	.inside.templateCHECKMARK {
		@apply tw-p-0 tw-border-none;
		.label,
		.iconContainer {
			@apply tw-hidden;
		}
		.fieldGroup {
			@apply tw-gap-0;
		}
	}
}
