.AddDeliveryAddress {
	:global(.validation),
	:global(.email-suggestion) {
		@apply tw-col-span-12 tw-m-0;
	}
	.AddDeliveryAddress-form {
		@apply tw-grid tw-grid-cols-12 tw-gap-y-4 tw-gap-x-4 tw-w-full tw-items-baseline;
	}
	.AddDeliveryAddress-control {
		@apply tw-w-full;
	}
	.AddDeliveryAddress-zipCodeControl {
		@apply tw-w-full;
	}
}

@screen md {
	.AddDeliveryAddress {
		.AddDeliveryAddress-form {
			width: 390px;
		}
		.AddDeliveryAddress-zipCodeControl {
			width: 95px;
		}
	}
}
