.container {
	@apply tw-relative;
}

.round {
	@apply tw-bg-gray-100 tw-border tw-border-solid tw-border-gray-100;
	border-radius: 50%;
	cursor: pointer;
	height: 19px;
	left: 0;
	position: absolute;
	top: 2px;
	width: 18px;
	print-color-adjust: exact;
	-webkit-print-color-adjust: exact;
}

.round:after {
	@apply tw-border-2 tw-border-solid tw-border-white tw-border-t-0 tw-border-r-0;
	content: "";
	height: 5px;
	left: 3px;
	opacity: 1;
	position: absolute;
	top: 5px;
	transform: rotate(-45deg);
	width: 10px;
	print-color-adjust: exact;
	-webkit-print-color-adjust: exact;
}
