.ChangeContactName {
	min-height: 106px;
	.ChangeContactName-fieldControl {
		@apply tw-w-full;
	}
}

@screen md {
	.ChangeContactName {
		.ChangeContactName-fieldControl {
			width: 315px;
		}
	}
}