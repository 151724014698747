.EditBillingAddress {
	.EditBillingAddress-form {
		@apply tw-grid tw-grid-cols-12 tw-gap-y-4 tw-gap-x-4 tw-w-full tw-items-baseline;
	}
	.EditBillingAddress-control {
		@apply tw-w-full;
	}
	.EditBillingAddress-zipCodeControl {
		@apply tw-w-full;
	}
}

@screen md {
	.EditBillingAddress {
		.EditBillingAddress-form {
			width: 390px;
		}
		.EditBillingAddress-zipCodeControl {
			width: 95px;
		}
	}
}