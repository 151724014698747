.footer-link-chat {
	@apply tw-mt-2;

	:focus-within, :hover {
		.footer-link-chat-image {
			@apply tw-invert;
		}
	}
}

.footer-link-chat-image {
	@apply tw--ml-2 tw-mr-2 tw--my-2;
}
