.AddressAutocomplete {
	@apply tw-min-w-full tw-border tw-border-solid tw-border-gray-400 tw-m-0 tw-bg-white tw-absolute tw-z-10 tw-max-h-[143px] tw-overflow-auto;
	li {
		@apply tw-px-2 tw-py-1 tw-cursor-pointer tw-select-none;
		&:hover {
			background: Highlight;
			color: HighlightText;
		}
	}
	.is-focused {
		background: Highlight;
		color: HighlightText;
	}
}