.AcctMgmt {
	min-height: 500px;
	.AcctMgmt-panel {
		@apply tw-px-2;

		@media print {
			 @apply tw-hidden;
		}
	}
}
