.OrderHistoryTable {
    width: 100%;
    @screen md {
        @apply tw-mb-5;
    }
    thead tr {
        border-bottom: 1px solid;
        @apply tw-border-brown;
    }
    th {
        @apply tw-font-bold tw-pb-2;
    }
    thead tr th {
        @apply tw-text-base tw-align-top;
    }
    td {
        @apply tw-align-top;
        @screen md {
            @apply tw-pt-3 tw-pb-12
        }
    }
    tr {
        border-bottom: 1px solid;
        @apply tw-flex tw-flex-wrap tw-py-4;

        @screen md {
            @apply tw-border-gray-400 tw-table-row;
        }
    }
    tr:last-child, tr:nth-last-child(2) {
        @screen sm {
            border-bottom: 0px !important;
            td {
                @apply tw-pb-0;
            }
        }
    }
   .Date, .OrderNumber, .Total {
       flex: 0 0 49%;
       @screen md {
           width: 200px;
       }
    }
    .Items {
        @screen md {
            width: 150px;
        }
    }
    .Total {
        @screen md {
            @apply tw-text-right;
            padding-right: 116px;
        }
    }
}

.OrderHistoryTooltip {
    @apply tw-grid tw-grid-cols-4 tw-gap-4;
    width: 550px;
    .MoreItems {
        @apply tw-flex tw-items-center tw-justify-center
    }
}

.SearchByOrderNumberBtn {
    @apply tw-font-normal tw-px-1;
    height: 27px;
}