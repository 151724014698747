.mainGrid {
	@apply tw-grid tw-relative tw-grid-cols-1 tw-gap-y-14 tw-gap-x-4 tw-m-2;

	@screen md {
		@apply tw-grid-cols-3 tw-mx-2;
	}
}

.SideBlocks {
	@apply tw-grid tw-gap-y-4 tw-auto-rows-min tw-p-2 tw-border tw-border-gray-400 tw-m-1;

	@screen md {
		@apply tw-m-0;
	}

	& > div {
		padding: 11px 7px 40px 7px;

		&:not(:last-child) {
			@apply tw-border-b tw-border-gray-400
		}
	}
}
