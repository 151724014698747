.footer-links {
	@apply tw-gap-4 tw-grid tw-grid-cols-2 tw-py-10;

	@screen md {
		 @apply tw-grid-cols-3 tw-px-4;
	}

	@screen lg {
		 @apply tw-grid-cols-6;
	}
}
