.MultiField {
	@apply tw-grid tw-grid-cols-12 tw-gap-x-2 tw-items-baseline tw-col-span-12;
	.MultiField-controls {
		@apply tw-flex tw-pt-1;
		> * {
			@apply tw-relative tw-pr-2 tw-shrink-0;
			&::after {
				@apply tw-block tw-bg-gray-300 tw-absolute tw-right-0;
				content: '';
				width: 1px;
				height: calc(100% - 7px);
				top: 50%;
				transform: translateY(-50%);
			}
		}
		> * + * {
			@apply tw-pl-2;
		}
		> *:last-child {
			@apply tw-pr-0;
			&::after {
				@apply tw-hidden tw-pr-0;
			}
		}
	}
}

@screen md {
	.MultiField {
		:global(.validation) {
			min-width: 300px;
		}
		.MultiField-controls {
			@apply tw-p-0;
		}
	}
}