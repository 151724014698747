.footer-container {
	@apply tw-flex tw-justify-center;

	@screen md {
		@apply tw-left-1/2 tw-relative tw-right-1/2;
		margin-left: calc(-50vw + 10px);
		margin-right: calc(-50vw + 10px);
		width: calc(100vw - 20px);
	}
}

.footer {
	@apply tw-border-t-gray tw-divide-y tw-divide-gray-400;
	max-width: 1600px;
}
