.footer-social {
	@apply tw-bg-gray tw-flex tw-flex-col tw-justify-between tw-items-center tw-gap-7 tw-py-8;

	@screen md {
		@apply tw-pr-4 tw-pl-7 tw-flex-row;
	}

	@screen lg {
		@apply tw-pr-8 tw-pl-12;
	}
}

.footer-social-certs {
	@apply tw-flex tw-items-center tw-mb-0 tw-gap-7;
}

.footer-social-links {
	@apply tw-flex tw-items-center tw-mb-0;
}

.footer-social-link-container {
	@apply tw-mx-3;
}

